<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" max-width="425">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          rounded
          x-small
          color="green"
          height="40"
          width="42"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ $auth.user.email ? $auth.user.email.charAt(0) : ($auth.user.name ? $auth.user.name.charAt(0) : '') }}
        </v-btn>
      </template>

      <v-card
        elevation="10"
        style="background-color: lightgray"
        :max-width="$vuetify.breakpoint.name === 'xs' ? '390px' : ''"
      >
        <v-list style="background-color: lightgray">
          <v-list-item class="mb-n4">
            <v-spacer />
            <span class="title">
              {{ this.$auth.user.email ? this.$auth.user.email : (this.$auth.user.name ? this.$auth.user.name : '') }}
            </span>
            <v-spacer />
          </v-list-item>
          <v-list-item>
            <v-spacer />
            <v-list-item-action>
              <v-btn
                elevation="2"
                rounded
                small
                :to="
                  $store.state.membership.name !== '' || !isCommercialVersion
                    ? {
                        name: 'Profile-Settings',
                      }
                    : { name: 'Without-Membership' }
                "
              >
                <v-icon> mdi-cog-outline </v-icon>
                {{ $t("userLogOutMenu.manageAccount") }}
              </v-btn>
            </v-list-item-action>
            <v-spacer />
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <t2t-membership-info v-if=isCommercialVersion />

        <v-divider v-if=isCommercialVersion />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="orange accent-2" small dark @click="logOut">
            {{ $t("logOut") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
// import { MembershipType } from "@/enums/membership/typeOfMembership";
import {Constants} from "../constants/Constants";

export default {
  data() {
    return {
      isCommercialVersion: Constants.IS_COMMERCIAL_VERSION,

      menu: false,
    };
  },
  methods: {
    logOut() {
      this.menu = false;
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style scoped></style>
